import * as React from "react";
import PageComponent from "src/components/pages/learn/who-needs-life-insurance";

const OurTeamPage = ({ location }) => {
  const copy = {
    text: `ביטוח חיים שומר מפני הפסד כלכלי שעלול להיוותר ללא מענה לאחר פטירתו של המבוטח. הנה כמה דוגמאות לצרכים שהגנה של ביטוח חיים יכולה לתת להם מענה.`,
    list: [
      {
        t: `החלפת הכנסה`,
        d: `כאשר אדם עובד, מייצר הכנסה, ותומך באחרים, נפטר באמצע שנות הכנסתו הכלכלית, ייווצר הפסד לאלו התלויים בו. ביטוח חיים יכול להחליף את נתח הכנסותיו העתידיות שנגדעו ע”י מוות בטרם עת.`,
      },
      {
        t: `שימור הון`,
        d: `לעיתים קרובות לאדם שצבר הון במשך חיים שלמים יש צורך בתוספת נזילות בעת הפטירה. בארה”ב, מס העיזבון שמגיע עד 40% מערך הנכסים המועברים לאחר הפטירה, יכול ליצור צורך חשוב מאוד בנזילות באותו הזמן.`,
      },
      {
        t: `תכנון ירושת העסק/ שוויון משפחתי בירושה`,
        d: `ברבים מהמקרים עסק משפחתי יוצר את הבסיס להון המשפחתי. כיצד יכולים ההורים לתכנן את הירושה בצורה שווה  כאשר חלק מהילדים שותפים בעסק וחלק לא? כסף נזיל זמין בעת הפטירה של בעל העסק יכול להשוות את הירושה כך שהשלום במשפחה וגם בעסק יישמרו.`,
      },
      {
        t: `ביטוח אנשי מפתח`,
        d: `עסקים לא עושים כסף, אנשים עושים. מותו של איש מפתח בעסק או שותף יכולים לגרום לעסק מצליח להתמוטט. כסף זמין בעת פטירתו של איש מפתח בעסק יכול לממן עבורך כשרון חדש שיחליף את זה שאבד.`,
      },
      {
        t: `ביטוח מכירה/ קנייה`,
        d: `האם הייתם רוצים שיהיו לכם עסקים יחד עם עורך הדין של בעלה השני של אלמנת שותפכם המנוח? ללא תכנון קנייה/ מכירה של העסק, וכסף זמין בעת הפטירה להוציא לפועל את התוכנית, סיוט זה יכול להפוך למציאות שלכם.`,
      },
      {
        t: `מורשת צדקה`,
        d: `מעשה נתינת הצדקה הוא מעשה מאוד אצילי. כיצד אדם יכול להבטיח שמען הצדקה היקר ללבו יקבל תמיכה גם לאחר פטירת התורם? ביטוח חיים יכול להיות פתרון מושלם.`,
      },
    ],
  };
  return (
    <PageComponent
      lang="he"
      location={location}
      copy={copy}
      pageTitle="מי צריך ביטוח חיים?"
    />
  );
};

export default OurTeamPage;
